import { useStaticQuery, graphql } from 'gatsby';

const WANPNG_AUTHOR = 'Wan PNG';

const getAuthorNameFromDashboardUser = (sourceContent) => {
  return sourceContent?.created_by?.username || '';
};

const getAPIUserCompany = (creatorId) => {
  const { allEmployer, allInstitution } = useStaticQuery(
    graphql`
      {
        allEmployer {
          nodes {
            companyId
            companyName
          }
        }

        allInstitution {
          nodes {
            companyId
            name
          }
        }
      }
    `
  );

  const employer = allEmployer?.nodes?.find((employer) => {
    return employer?.companyId === creatorId;
  });

  const institution = allInstitution?.nodes?.find((institution) => {
    return institution?.companyId === creatorId;
  });

  return employer?.companyName || institution?.name;
};

const selectAuthor = (
  destinationContent,
  sourceContent = destinationContent
) => {
  const isWanPNG =
    !sourceContent?.creator_id && !sourceContent?.created_by?.username;
  if (isWanPNG) {
    return {
      ...destinationContent,
      author: WANPNG_AUTHOR,
    };
  }

  const isDashboardUser = !sourceContent?.creator_id;
  if (isDashboardUser) {
    const authorNameFromDashboardUser = getAuthorNameFromDashboardUser(
      sourceContent
    );
    return {
      ...destinationContent,
      author: authorNameFromDashboardUser,
    };
  }

  const isAPIUser = !!sourceContent?.creator_id;
  if (isAPIUser) {
    const authorNameFromAPIUser = getAPIUserCompany(sourceContent?.creator_id);

    return {
      ...destinationContent,
      author: authorNameFromAPIUser,
    };
  }

  return {
    ...destinationContent,
    author: '',
  };
};

export default selectAuthor;
